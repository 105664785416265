import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Icon, Input, Button } from 'antd';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class LoginForm extends Component {

  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = e => {

    const {login} = this.props;

    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        login(values);
      }
    });

  };

  render() {

    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

    const usernameError = isFieldTouched('username') && getFieldError('username');

    const passwordError = isFieldTouched('password') && getFieldError('password');

    return (
      <Form data-testid={`login-form`} layout="vertical" onSubmit={this.handleSubmit}>
        <div className="form-item-title">Email/Username</div>
        <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input
              data-testid={`username`}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="youremail@domain.com"
            />,
          )}
        </Form.Item>
        <div className="form-item-title">Password</div>
        <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              data-testid={`password`}
              placeholder="********"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button data-testid={`signin`} type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
            Sign In
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedLoginForm = Form.create({ name: 'login' })(LoginForm);

export default connect(null, {})(WrappedLoginForm);