import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";

import style from "./InfoPanel.module.scss";

import TumorInfoPercent from "./components/tumorInfoPercent";
import Spinner from "GenericComponents/spinner";
import SimpleSelect from "GenericComponents/simpleSelect";

import {
  getTestId,
  getTestPanelType,
  getTumorInfoLocation,
  getInfoPanelMode,
  getTumorInfoPercent,
  getTumorInfoType,
  getInfoPanelIsLoading,
  getTestSampleType,
  getTestGeneralInfoGender,
  getTestGeneralInfoAge,
} from "Store/selectors";
import {
  setTumorInfo,
  setInfoPanelMode,
  setTestSampleType,
  setTestGeneralInfo,
} from "Actions/testActions";
import {
  TUMOR_LOCATION,
  TUMOR_TYPE,
  SAMPLE_TYPE_OPTIONS,
  GENDER_OPTIONS,
  AGE_OPTIONS,
} from "Utils/constants";

class InfoPanel extends Component {
  handelClose = () => {
    this.props.setInfoPanelMode(false);
  };

  handelTumorTypeSelect = e => {
    const { testId, setTumorInfo } = this.props;
    setTumorInfo({ testId, name: 'type', value: e.target.value });
  };

  handelTumorLocationSelect = e => {
    const { testId, setTumorInfo } = this.props;
    setTumorInfo({ testId, name: 'location', value: e.target.value });
  };

  handelTumorPercentSelect = value => {
    const { testId, setTumorInfo } = this.props;
    setTumorInfo({ testId, name: 'cancer_cell_percentage', value });
  };

  handleTestSampleType = e => {
    const { testId, setTestSampleType } = this.props;
    setTestSampleType({ testId, value: e.target.value });
  };

  handleTestGeneralInfoGender = e => {
    const { testId, setTestGeneralInfo } = this.props;
    setTestGeneralInfo({ testId, name: 'gender', value: e.target.value });
  };

  handleTestGeneralInfoAge = e => {
    const { testId, setTestGeneralInfo } = this.props;
    setTestGeneralInfo({ testId, name: 'age', value: e.target.value });
  };

  render() {
    const {
      tumorInfoType,
      tumorInfoLocation,
      tumorInfoPercent,
      infoPanelIsLoading,
      testPanelType,
      testSampleType,
      testGeneralInfoGender,
      testGeneralInfoAge,
    } = this.props;

    return (
      <div className={style["info-panel-wrapper"]}>
        {infoPanelIsLoading ? <Spinner/> :
          <Fragment>
            <div className="panel-type-wrapper">
              <div className="info-panel-label">Panel type: </div>
              <div className="info-panel-value">{testPanelType}</div>
            </div>

            <div className="sample-type-wrapper">
              <div className="info-panel-label">Sample type: </div>
              <div className="info-panel-select">
                <SimpleSelect
                  value={testSampleType}
                  options={SAMPLE_TYPE_OPTIONS}
                  onChange={this.handleTestSampleType}
                />
              </div>
            </div>

            <div className="tumor-info-wrapper">
              <div className="info-panel-label">Tumor: </div>
              <div className="info-panel-select tumor-info-type">
                <SimpleSelect
                  value={tumorInfoType}
                  options={TUMOR_TYPE.map(v => ({ label: v, value: v }))}
                  onChange={this.handelTumorTypeSelect}
                  placeholder="Missing type"
                />
              </div>
              <div className="info-panel-select tumor-info-location">
                <SimpleSelect
                  value={tumorInfoLocation}
                  options={TUMOR_LOCATION.map(v => ({ label: v, value: v }))}
                  onChange={this.handelTumorLocationSelect}
                  placeholder="Missing location"
                />
              </div>
              <div className="tumor-info-percent">
                <TumorInfoPercent
                  value={tumorInfoPercent}
                  placeholder="Missing %"
                  onChange={this.handelTumorPercentSelect}
                />
              </div>
            </div>

            <div className="patient-wrapper">
              <div className="info-panel-label">Patient: </div>
              <div className="info-panel-select patient-gender">
                <SimpleSelect
                  value={testGeneralInfoGender}
                  options={GENDER_OPTIONS}
                  onChange={this.handleTestGeneralInfoGender}
                />
              </div>
              <div className="info-panel-select patient-age">
                <SimpleSelect
                  value={testGeneralInfoAge}
                  options={AGE_OPTIONS}
                  onChange={this.handleTestGeneralInfoAge}
                />
              </div>
            </div>
          </Fragment>
        }
      </div>
    );
  }
}

InfoPanel.propTypes = {};

const mapStateToProps = state => {
  return {
    infoPanelMode: getInfoPanelMode(state),
    infoPanelIsLoading: getInfoPanelIsLoading(state),
    tumorInfoType: getTumorInfoType(state),
    tumorInfoLocation: getTumorInfoLocation(state),
    tumorInfoPercent: getTumorInfoPercent(state),
    testId: getTestId(state),
    testPanelType: getTestPanelType(state),
    testSampleType: getTestSampleType(state),
    testGeneralInfoGender: getTestGeneralInfoGender(state),
    testGeneralInfoAge: getTestGeneralInfoAge(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setInfoPanelMode: data => dispatch(setInfoPanelMode(data)),
    setTumorInfo: data => dispatch(setTumorInfo(data)),
    setTestSampleType: data => dispatch(setTestSampleType(data)),
    setTestGeneralInfo: data => dispatch(setTestGeneralInfo(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoPanel);
