import _ from "lodash";
import actionsTypes from '../actionsTypes';
import createReducer from "./createReducer";

const initialState = {
  isLoggedIn: false,
  status: null,
  serverError: null,
  userEmail: null,
  apiKey: ""
};

const authReducer = createReducer(initialState, {
  [actionsTypes.LOGIN_SUCCESS]: (state, {payload}) => {

    const {apiKey} = payload;

    let newState = Object.assign({}, state);

    newState.isLoggedIn = true;

    newState.apiKey = apiKey;
    
    state = newState;

    if (apiKey){
      return {
        ...state
      };
    }
  },

  [actionsTypes.LOGOUT]: (state, {payload}) => {

    return {
      ...state,
      isLoggedIn: false,
      ...payload
    };
  },

  [actionsTypes.LOGIN_RESULT]: (state, {payload}) => {
    return {
      ...state,
      isLoggedIn: !_.has(payload, "message"),
      serverError: null,
      ...payload
    };
  },

});


export default authReducer;
