import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Tooltip } from "antd";
import style from "./FinalReportActionableTable.module.scss";
import TableLayout from "Pages/singleTestPage/components/tableLayout";
import LabeledTag from "GenericComponents/labeledTag/LabeledTag";
import {
  GERMLINE_VARIANT_CLASS_OPTIONS,
  SOMATIC_VARIANT_CLASS_OPTIONS,
  TEXTS
} from "Utils/constants";
import MultiLineText from "GenericComponents/multiLineText";
import { ReactComponent as OkIcon } from "Assets/ok.svg";
import { ReactComponent as DeliteIcon } from "Assets/bin-new.svg";
import { convertVaf } from "Utils/helpers";

class FinalReportActionableTable extends Component {
  state = {
    columns: [
      {
        title: "Gene",
        dataIndex: "gene",
        key: "1",
        width: 150
      },
      {
        title: "Variant",
        dataIndex: "variant",
        key: "2",
        width: 150
      },
      {
        title: "Classification",
        dataIndex: "classification",
        key: "4",
      },
      {
        title: "VAF",
        dataIndex: "percentage_variants",
        key: "5",
        width: 90
      },
      {
        title: () => <div><div>Approved drug </div><div>(same indication)</div></div>,
        dataIndex: "approvedDrugSame",
        key: "7",
      },
      {
        title: () => <div><div>Approved drug </div><div>(other indication)</div></div>,
        dataIndex: "approvedDrugOther",
        key: "8",
      },
      {
        title: "Clinical trials",
        dataIndex: "clinical_trials",
        key: "9",
      },
      {
        dataIndex: "remove",
        key: "10",
      }
    ]
  };

  handleRemoveRow = (id, e) => {
    e.stopPropagation();
    const { testId, remove } = this.props;
    remove({ testId, id });
  };

  columnsConverter = columns => {
    return columns.map(column => {
      if (column.dataIndex === "classification") {
        column.render = (text, record) => {
          const { germline_class, somatic_class, zygosity } = record;
          let value, typeData;

          if (zygosity === TEXTS.somatic) {
            value = somatic_class;
            typeData = SOMATIC_VARIANT_CLASS_OPTIONS;
          } else {
            value = germline_class;
            typeData = GERMLINE_VARIANT_CLASS_OPTIONS;
          }

          return (
            <div>
              <div className="n-table-cell label-tag">
                <LabeledTag typeData={typeData} value={value} />
              </div>
            </div>
          );
        };
        column.className = "no-padding";
      } else if (column.dataIndex === "percentage_variants") {
        column.render = (text) => {
          return (
            <div className="n-table-cell">
              <div>{text ? `${convertVaf(text)}%` : ``}</div>
            </div>
          );
        };
      } else if (column.dataIndex === "variant") {
        column.render = (text, record) => {
          const { hgvs_p, hgvs_c, status, mutation_type } = record;

          return status && mutation_type === TEXTS.CNV ? (
            <div className="n-table-cell">
              <div>{status}</div>
            </div>
          ) : (
            <div className="n-table-cell">
              <MultiLineText
                source={{ hgvs_p, hgvs_c }}
                className="multiLine-text"
              />
            </div>
          );
        };
      } else if (column.dataIndex === "approvedDrugSame") {
        column.render = (data) => {
          return (
            <div className="n-table-cell">
              {data.map((item, index) => <div key={index}>{item.drugName}</div>)}
            </div>
          );
        };
      } else if (column.dataIndex === "approvedDrugOther") {
        column.render = (data) => {
          return (
            <div className="n-table-cell">
              {data.map((item, index) => <div key={index}>{item.drugName}</div>)}
            </div>
          );
        };
      } else if (column.dataIndex === "clinical_trials") {
        column.render = (data) => {
          return (
            <div className="n-table-cell">
              <div className="ok-icon">{!!data?.length && <OkIcon />}</div>
            </div>
          );
        };
      } else if (column.dataIndex === "remove") {
        column.render = (text, record) => {
          const { id } = record;
          return (
            <div className="n-table-cell">
              <div
                className="delete-icon"
                onClick={e => this.handleRemoveRow(id, e)}
              >
                {<DeliteIcon />}
              </div>
            </div>
          );
        };
      } else {
        column.render = value => {
          return (
            <div className="n-table-cell">
              <Tooltip placement="topLeft" title={value}>
                <div>{value}</div>
              </Tooltip>
            </div>
          );
        };
        column.className = "no-padding";
      }

      return column;
    });
  };

  handleRowClick = obj => {
    this.props.handleSelectRow(obj.id);
  };

  render() {
    const { dataSource } = this.props;

    const columns = this.columnsConverter(this.state.columns);
    return (
      <TableLayout>
        <Table
          className={style["new-table"]}
          columns={columns}
          onRow={(record) => {
            return {
              onClick: e => this.handleRowClick(record, e)
            };
          }}
          dataSource={dataSource}
          pagination={false}
          rowKey="id"
        />
      </TableLayout>
    );
  }
}

FinalReportActionableTable.propTypes = {
  selectedData: PropTypes.array
};

FinalReportActionableTable.defaultProps = {
  selectedData: []
};

export default FinalReportActionableTable;
