import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Tooltip } from "antd";
import style from "./FinalReportClinicalTable.module.scss";
import TableLayout from "Pages/singleTestPage/components/tableLayout";
import LabeledTag from "GenericComponents/labeledTag/LabeledTag";
import {
  GERMLINE_VARIANT_CLASS_OPTIONS,
  SOMATIC_VARIANT_CLASS_OPTIONS,
  TEXTS
} from "Utils/constants";
import MultiLineText from "GenericComponents/multiLineText";
import { ReactComponent as DeliteIcon } from "Assets/bin-new.svg";

class FinalReportClinicalTable extends Component {
  state = {
    columns: [
      {
        title: "Gene",
        dataIndex: "gene",
        key: "1",
        width: 120
      },
      {
        title: "Variant",
        dataIndex: "variant",
        key: "2",
        width: 120
      },
      {
        dataIndex: "remove",
        key: "10",
        width: 80
      }
    ]
  };

  handleRemoveRow = id => {
    const { testId, remove } = this.props;
    remove({ testId, id });
  };

  columnsConverter = columns => {
    return columns.map(column => {

      if (column.dataIndex === "classification") {
        column.render = (text, record) => {
          const { germline_class, somatic_class, zygosity } = record;
          let value, typeData;

          if (zygosity === TEXTS.somatic) {
            value = somatic_class;
            typeData = SOMATIC_VARIANT_CLASS_OPTIONS;
          } else {
            value = germline_class;
            typeData = GERMLINE_VARIANT_CLASS_OPTIONS;
          }

          return (
            <div className="n-table-cell">
              <div className="label-custom-style">
                <LabeledTag typeData={typeData} value={value} />
              </div>
            </div>
          );
        };
        column.className = "no-padding";
      } else if (column.dataIndex === "variant") {
        column.render = (text, record) => {
          const { hgvs_p, hgvs_c, status, mutation_type } = record;

          return status && mutation_type === TEXTS.CNV ? (
            <div className="n-table-cell">
              <div>{status}</div>
            </div>
          ) : (
            <div className="n-table-cell">
              <MultiLineText
                source={{ hgvs_p, hgvs_c }}
                className="multiLine-text"
              />
            </div>
          );
        };
      } else if (column.dataIndex === "remove") {
        column.render = (text, record) => {
          const { id } = record;
          return (
            <div className="n-table-cell">
              <div
                className="delete-icon"
                onClick={e => this.handleRemoveRow(id, e)}
              >
                {<DeliteIcon />}
              </div>
            </div>
          );
        };
      } else {
        column.render = value => {
          return (
            <div className="n-table-cell">
              <Tooltip placement="topLeft" title={value}>
                <div>{value}</div>
              </Tooltip>
            </div>
          );
        };
        column.className = "no-padding";
      }

      return column;
    });
  };

  render() {
    const { dataSource } = this.props;
    const columns = this.columnsConverter(this.state.columns);

    return (
      <TableLayout>
        <Table
          className={style["new-table"]}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          // scroll={{ x: "max-content", y: false }}
          rowKey="id"
        />
      </TableLayout>
    );
  }
}

FinalReportClinicalTable.propTypes = {
  selectedData: PropTypes.array
};

FinalReportClinicalTable.defaultProps = {
  selectedData: []
};

export default FinalReportClinicalTable;
