import createReducer from "./createReducer";
import actionsTypes from "../actionsTypes";

const initialState = {
  data: []
};

const variantConflictsReducer = createReducer(initialState, {
  [actionsTypes.SET_VARIANT_CONFLICTS]: (state, { payload }) => {
    return {
      ...state,
      data: payload
    };
  },

  [actionsTypes.CLEAR_VARIANTS_CONFLICTS]: () => {
    return initialState;
  }
});

export default variantConflictsReducer;
