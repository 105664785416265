import React, {Component} from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

import "Css/App.scss";

import SingleTestPage from "Pages/singleTestPage";
import Header from "Pages/singleTestPage/components/header";
import ResultConfig from "Pages/singleTestPage/components/resultConfig";
import EvidenceConfig from "variantComponents/evidenceConfig";
import VariantPage from "variantPage/";
import TestsPage from "Pages/testsPage";
import ConfirmationPage from "Pages/confirmationPage";
import ProtectedRoute from "./pages/loginPage/components/ProtectedRoute";
import LoginPage from "./pages/loginPage";
import FinalReportPage from "./pages/finalReportPage";
// import CoveragePage from "./pages/coveragePage";

import Alert from "GenericComponents/alert";

import {
  getLoginStatus,
  getApiKey,
  getAlertStatus,
  getAlertTitle,
  getAlertMessage,
} from "./store/selectors";
import {
  setAlert
} from "Actions/alertActions";
import {
  setToken,
} from "./api/axios-base";

class App extends Component{

  constructor(props){
    super(props);
    const { apiKey } = props;
    if (apiKey) {
      setToken(apiKey);
    }
  }

  render() {
    const {
      isLoggedIn,
      alertStatus,
      alertTitle,
      alertMessage,
      setAlert,
    } = this.props;

    return (
      <div className="App">
        <div className="App-header">
          <Header />
        </div>
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/" exact component={TestsPage} />
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/tests/:testId" exact component={SingleTestPage} />
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/tests/:testId/variants/:variantId/" component={VariantPage} />
          <Route path="/confirmations/:confirmationId" component={ConfirmationPage} />
          {/* <Route path="/coverage/" component={CoveragePage} /> */}
          <ProtectedRoute isLoggedIn={isLoggedIn} path="/final-report/:testId" exact component={FinalReportPage} />
        </Switch>
        <ResultConfig />
        <EvidenceConfig />
        {!!alertStatus && (
          <Alert
            status={alertStatus}
            title={alertTitle}
            message={alertMessage}
            onClose={setAlert.bind(null, null)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: getLoginStatus(state),
    apiKey: getApiKey(state),
    alertStatus: getAlertStatus(state),
    alertTitle: getAlertTitle(state),
    alertMessage: getAlertMessage(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setAlert: data => dispatch(setAlert(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

