import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LabeledTag from "GenericComponents/labeledTag";
import ErrorIcon from 'Assets/error-notification.svg';
import ArrowIcon from 'Assets/arrowDown.svg';
import style from './VariantConflictsPendingClassificationItem.module.scss';
import { VARIANT_CLASS_GERMLINE, VARIANT_CLASS_SOMATIC } from 'Utils/constants';
import { getFormatedDate } from 'Utils/helpers';

const VariantConflictsPendingClassificationItem = ({ item }) => {
  if (!item) return '';

  const {
    updated_at,
    payload: {
      germline_class,
      somatic_class,
      gsid
    } = {},
    user: {
      name: userName
    } = {}
  } = item;

  const [isWrapped, setIsWrapped] = useState(true);

  let classificationEntity = germline_class
    ? VARIANT_CLASS_GERMLINE[germline_class]
    : VARIANT_CLASS_SOMATIC[somatic_class];

  return (
    <div className={style["variant-conflict-pending-classification-item"]}>

      {isWrapped &&
        <div
          className="pending-classification-wrapped"
          onClick={() => setIsWrapped(false)}
        >
          <div
            className="conflict-icon"
            style={{
              backgroundImage: `url(${ErrorIcon})`
            }}
          />
          <div className="wrapped-conflict-text">
            <span>Pending Classification:</span>
            <LabeledTag
              label={classificationEntity?.label}
              tagColor={classificationEntity?.tagColor}
            />
          </div>
        </div>
      }
      {!isWrapped &&
        <div className="pending-classification-unwrapped">
          <div className="conflict-title">Pending Classification:</div>
          <div className="conflict-date">{getFormatedDate(updated_at)}</div>
          <div className="conflict-gsid">{getFormatedDate(gsid)}</div>
          <div className="conflict-gsid">{userName}</div>
          <div className="conflict-tag">
            <LabeledTag
              label={classificationEntity?.label}
              tagColor={classificationEntity?.tagColor}
            />
          </div>
          <button className="conlict-wrap-btn"
            style={{
              backgroundImage: `url(${ArrowIcon})`
            }}
            onClick={() => setIsWrapped(true)}
          />
        </div>
      }

    </div>
  );
};

VariantConflictsPendingClassificationItem.propTypes = {
  item: PropTypes.object
};
VariantConflictsPendingClassificationItem.defaultProps = {
  item: null
};

export default React.memo(VariantConflictsPendingClassificationItem);
