import createReducer from "./createReducer";
import actionsTypes from "../actionsTypes";

const initialState = {
  isLoading: false,
  gsid: null,
  panel_type: null,
  sample_type: null,
  id: null,
  infoPanelMode: false,
  mutation_types: [],
  tumor_info: {
    type: "",
    location: "",
    cancer_cell_percentage: null
  },
  infoPanelIsLoading: false,
  phenotype: null,
  general_info: {
    gender: null,
    age: null,
  },
};

const testReducer = createReducer(initialState, {
  [actionsTypes.SET_TEST_DATA]: (state, { payload }) => {
    return {
      ...initialState, // important to clear all previous state data
      ...payload,
      infoPanelMode: state.infoPanelMode // except infoPanelMode
    };
  },

  [actionsTypes.SET_INFO_PANEL_MODE]: (state, { payload }) => {
    return {
      ...state,
      infoPanelMode: payload
    };
  },

  // [actionsTypes.SET_TUMOR_INFO]: (state, { payload }) => {
  //   // const {type: [val](|| location || cancer_cell_percentage)} = payload;
  //   return {
  //     ...state,
  //     tumor_info: {
  //       ...state.tumor_info,
  //       ...payload
  //     }
  //   };
  // },

  [actionsTypes.SET_LOADING]: (state, { payload }) => {
    return {
      ...state,
      isLoading: payload
    };
  },

  [actionsTypes.SET_INFO_PANEL_IS_LOADING]: (state, { payload }) => {
    return {
      ...state,
      infoPanelIsLoading: payload
    };
  }
});

export default testReducer;
