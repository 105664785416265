import React from "react";
import PropTypes from "prop-types";
import {
  // InputNumber,
  Slider,
  // Tooltip,
} from "antd";
import style from "./TumorInfoPercent.module.scss";
// import { ReactComponent as SaveIcon } from "Assets/save.svg";

const TumorInfoPercent = ({
  value,
  onChange
}) => {

  return (
    <div className={style["tumor-info-percent-wrapper"]}>
      <div className={style["title"]}>
        % Cancer Cells
      </div>
      <div className={style["value"]}>
        {value}%
      </div>
      <div className={style["slider"]}>
        {!value && <Slider
          defaultValue={0}
          onAfterChange={onChange}
        />}
        {!!value && <Slider
          defaultValue={value}
          onAfterChange={onChange}
        />}

      </div>
    </div>
  );
};

TumorInfoPercent.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
TumorInfoPercent.defaultProps = {
  value: null,
};

export default TumorInfoPercent;

