import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import cn from 'classnames';

import Portal from 'GenericComponents/portal';
import styles from './ExportConfirmPopup.module.scss';
import btnImg from 'Assets/close-big.svg';

const ExportConfirmPopup = ({ onSubmit, onCancel }) => {
  return (
    <Portal>
      <div className={styles['export-confirm-popup']}>
        <div className={styles['header']}>
          <div className={styles['header-title']}>
            Export
          </div>
          <button
            className={styles['header-close']}
            style={{backgroundImage: `url(${btnImg})`}}
            onClick={onCancel}
          />
        </div>
        <div className={styles['content']}>
          <div className={styles['text']}>
            You are about to lock the Variants table.
            <br/>
            <br/>
            Auto-suggestions of classification for variants will not be available.
            This way the Variant Table will stay intact
            if you need to check it later and you can proceed to work on Final Report.
              You still can edit and update the classification of variants in this Test.
            Please confirm.
          </div>
          <div className={styles['content-footer']}>
            <div className={styles['btns']}>
              <Button
                className={cn([
                  styles['btn'],
                ])}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                className={cn([
                  styles['btn'],
                  styles['btn--submit'],
                ])}
                onClick={onSubmit}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

ExportConfirmPopup.propTypes = {
  onSubmit: PropTypes.func.isRequires,
  onCancel: PropTypes.func.isRequires,
};

ExportConfirmPopup.defaultProps = {};

export default React.memo(ExportConfirmPopup);
