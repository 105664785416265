import actionsTypes from "../actionsTypes";

export const fetchVariantConflicts = data => {
  return {
    type: actionsTypes.FETCH_VARIANT_CONFLICTS,
    payload: data
  };
};

export const setVariantConflicts = data => {
  return {
    type: actionsTypes.SET_VARIANT_CONFLICTS,
    payload: data
  };
};

export const pushVariantUsedConflict = data => {
  return {
    type: actionsTypes.PUSH_VARIANT_USED_CONFLICT,
    payload: data
  };
};

export const clearVariantConflicts = data => {
  return {
    type: actionsTypes.CLEAR_VARIANTS_CONFLICTS,
    payload: data
  };
};
