import React, { Component } from "react";
import { Checkbox, Table, Tooltip } from "antd";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import LabeledTag from "GenericComponents/labeledTag/LabeledTag";
import {
  GERMLINE_VARIANT_CLASS_OPTIONS, SOMATIC_VARIANT_CLASS_OPTIONS,
  VARIANT_CLASS_GERMLINE,
  VARIANT_CLASS_SOMATIC
} from "Utils/constants";
import {
  convertVaf,
  convertCoverage
} from "Utils/helpers";

import style from "./FinalReportVariantsTable.module.scss";

class FinalReportVariantsTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          key: "1",
          dataIndex: "selection",
          width: 50,
          fixed: "left",
          className: "selection-cell"
        },
        {
          title: "Gene",
          dataIndex: "gene",
          key: "2",
          width: 120
        },
        {
          title: "Chr: position",
          dataIndex: "chrPosition",
          key: "3",
          width: 150
        },
        {
          title: "Transcript",
          dataIndex: "transcript",
          key: "4",
          width: 120
        },
        {
          title: "Allele change",
          dataIndex: "alleleChange",
          key: "6",
          width: 120
        },
        {
          title: "Coding",
          dataIndex: "coding",
          key: "7",
          width: 120
        },
        {
          title: "Protein",
          dataIndex: "protein",
          key: "8",
          width: 120
        },
        {
          title: "VAF",
          dataIndex: "vaf",
          key: "9",
          width: 80,
          className: "sorter numeric"
        },
        {
          title: "Zygosity",
          dataIndex: "zygosity",
          key: "10",
          width: 100
        },
        {
          title: "Germline Class",
          dataIndex: "variantClassGermline",
          key: "11",
          width: 140
        },
        {
          title: "Somatic Class",
          dataIndex: "variantClassSomatic",
          key: "12",
          width: 140
        },
        {
          title: "Coverage",
          dataIndex: "coverage",
          key: "13",
          width: 120,
          className: "numeric"
        },
        {
          title: "Notes",
          dataIndex: "notes",
          key: "14",
          width: 300
        }
      ]
    };
  }

  handleCheckboxChange = () => {
    const { isAllRowSelected, handleSelectAllRows } = this.props;
    handleSelectAllRows(isAllRowSelected);
  };

  columnsConverter = columns => {
    return columns.map(column => {

      // construction if/else is required

      if (column.dataIndex === "selection") {
        const { isAllRowSelected } = this.props;
        column.title = (
          <div
            className={cn("table-header-selection-chbx")}
          >
            <Checkbox
              checked={isAllRowSelected}
              onChange={this.handleCheckboxChange}
            />
          </div>
        );

        column.render = (text, record) => {
          return (
            <div className="n-table-cell">
              <Checkbox
                checked={record.selected}
                onChange={this.props.handleSelectedRow.bind(null, {
                  item: record,
                  value: !record.selected
                })}

                data-testid="selection-checkbox"
                data-testitemid={record.id}
              />
            </div>
          );
        };
      } else if (column.dataIndex === "zygosity") {
        column.render = (text, record) => (
          <div className="n-table-cell">
            {record.zygosity}
          </div>
        );
      } else if (column.dataIndex === "variantClassGermline") {
        column.render = (text, record) => {
          return (
            <div className="n-table-cell">
              <div className={cn("table-select-wrapper", {"hidden-classification": record.zygosity === "somatic"})}>
                <div>
                  <LabeledTag
                    typeData={GERMLINE_VARIANT_CLASS_OPTIONS}
                    label={VARIANT_CLASS_GERMLINE[text]?.label}
                  />
                </div>
              </div>
            </div>
          );
        };
        column.className = "select";
      } else if (column.dataIndex === "variantClassSomatic") {
        column.render = (text, record) => {
          return (
            <div className="n-table-cell">
              <div
                className={cn("table-select-wrapper",
                  {"hidden-classification": ["homo", "hetero", "hemi"].includes(record.zygosity)})}
              >
                <div>
                  <LabeledTag
                    typeData={SOMATIC_VARIANT_CLASS_OPTIONS}
                    label={VARIANT_CLASS_SOMATIC[text]?.label}
                  />
                </div>
              </div>
            </div>
          );
        };
      } else if (column.dataIndex === "notes") {
        column.render = (text, record) => {
          return (
            <div className="n-table-cell">
              <div>{record.notes}</div>
            </div>
          );
        };
      } else if (column.dataIndex === "transcript") {
        column.render = (text, record) => {
          return (
            <div className="n-table-cell">
              {record.transcript}
            </div>
          );
        };
      } else if (column.dataIndex === "chrPosition") {
        column.render = (text, record) => {
          const { chrPosition } = record;
          return (
            <div className="n-table-cell">
              {chrPosition}
            </div>
          );
        };
      }
      else if (column.dataIndex === "alleleChange") {
        column.render = (text) => {
          return (
            <div className="n-table-cell">
              <div>{text}</div>

            </div>
          );
        };
      } else if (column.dataIndex === "coding") {
        column.render = (text) => {
          return (
            <div className="n-table-cell">
              <div>{text}</div>
            </div>
          );
        };
      } else if (column.dataIndex === "protein") {
        column.render = (text, record) => {
          const { proteinWholly } = record;
          return (
            <div className="n-table-cell">
              <Tooltip placement="topLeft" title={proteinWholly}>
                <div>{text}</div>
              </Tooltip>
            </div>
          );
        };
      } else if (column.dataIndex === "vaf"){
        column.render = (text) => {
          return (
            <div className="n-table-cell">
              {convertVaf(text)}
            </div>
          );
        };
      } else if (column.dataIndex === "coverage"){
        column.render = (text) => {
          return (
            <div className="n-table-cell">
              {convertCoverage(text)}
            </div>
          );
        };
      } else {
        column.render = (text) => {
          return (
            <div className="n-table-cell">
              {text || ""}
            </div>
          );
        };
      }

      return column;
    });
  };

  render() {
    const { selectedVariants } = this.props;

    const columns = this.columnsConverter(this.state.columns);

    if (!selectedVariants) {
      return <div>no data</div>;
    }

    return (
      <Table
        className={style["new-table"]}
        pagination={{ pageSize: 5 }}
        columns={columns}
        dataSource={selectedVariants}
        scroll={{ x: "max-content", y: false }}
      />
    );
  }
}

export default withRouter(FinalReportVariantsTable);
