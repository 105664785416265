import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "antd";
import cn from "classnames";

import style from "./Header.module.scss";

import { ReactComponent as NotificationIcon } from "Assets/notifications.svg";
import { ReactComponent as InfoIcon } from "Assets/info.svg";
import LockImg from 'Assets/lock.svg';

import HeaderIcon from "GenericComponents/headerIcon";
import User from "Pages/singleTestPage/components/header/components/user";
import GoBackButton from "Pages/singleTestPage/components/header/components/goBackButton";
import InfoPanel from "Pages/singleTestPage/components/infoPanel";

import {
  getTestGsid,
  getTestId,
  getInfoPanelMode,
  getTestPhenotype,
  getTestExportedAt,
  getLoginStatus
} from "Store/selectors";
import {
  logout
} from "Store/actions/authActions";
import {
  setInfoPanelMode
} from "Actions/testActions";

import { TEXTS } from "Utils/constants";
import { layout } from "Utils/helpers";

class Header extends Component {
  constructor(props) {
    super(props);
    this.isMVP = process.env.REACT_APP_MVP_CONFIG === "true";
  }

  handelNotification = e => {
    console.log(e.target);
  };

  handelInfo = () => {
    const { isShowInfoPanel, setInfoPanelMode } = this.props;
    setInfoPanelMode(!isShowInfoPanel);
  };

  logout = () => {
    this.props.logout();
  }

  renderInfoIcon = () => {
    const { isShowInfoPanel } = this.props;
    return (
      <div className="right-side-item">
        <HeaderIcon
          isActive={isShowInfoPanel}
          customClassName={"info"}
          icon={<InfoIcon />}
          handelOnClick={this.handelInfo}
        />
      </div>
    );
  };

  render() {
    const {
      testId,
      location,
      gsId,
      phenotype ,
      isTestExported,
      isLoggedIn,
      isShowInfoPanel
    } = this.props;

    return (
      <div className={style["header-wrapper"]}>
        <div className="header-inner">
          <div className="flex justify-start flex-row items-center">
            <div className="left-wrapper">
              Analyst
            </div>
            <div className="left-wrapper">
              <GoBackButton
                pathname={location.pathname}
                testId={testId}
                text={TEXTS.goBack}
                className={"go-back-button"}
              />
            </div>
            {isLoggedIn &&  <Button onClick={this.logout}>Logout</Button>}

            {!layout(location.pathname, TEXTS.testsPage) && (
              <Fragment>
                <div className="left-wrapper">{gsId}</div>
                {isTestExported && <div className="left-wrapper">
                  <div
                    className="lock-img"
                    style={{
                      backgroundImage: `url(${LockImg})`
                    }}
                  />
                </div>}
                <div className="left-wrapper">{phenotype}</div>
              </Fragment>
            )}
          </div>
          <div className="flex justify-start flex-row">
            {(layout(location.pathname, TEXTS.singleTestPage)
              || layout(location.pathname, TEXTS.variantPage)
              || layout(location.pathname, TEXTS.finalReportPage))
              && this.renderInfoIcon()
            }
            {!this.isMVP && (
              <Fragment>
                <div className="right-side-item">
                  <HeaderIcon
                    // isActive
                    customClassName={"notification"}
                    icon={<NotificationIcon />}
                    handelOnClick={this.handelNotification}
                  />
                </div>

                <div className="right-side-item">
                  <User
                    userName={"vadim malckin"}
                    avatarUrl={`https://randomuser.me/api/portraits/men/34.jpg`}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </div>
        {(layout(location.pathname, TEXTS.singleTestPage)
          || layout(location.pathname, TEXTS.variantPage)
          || layout(location.pathname, TEXTS.finalReportPage))
          && <div className={cn(["header-info-panel", { out: isShowInfoPanel }])}>
            <InfoPanel />
          </div>
        }
      </div>
    );
  }
}

// Header.propTypes = {};

const mapStateToProps = state => {
  return {
    isShowInfoPanel: getInfoPanelMode(state),
    testId: getTestId(state),
    gsId: getTestGsid(state),
    phenotype: getTestPhenotype(state),
    isTestExported: !!getTestExportedAt(state),
    isLoggedIn: getLoginStatus(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setInfoPanelMode: data => dispatch(setInfoPanelMode(data)),
    logout: data => dispatch(logout(data))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
