import React, { Component } from "react";
import { connect } from "react-redux";
import style from "./SingleTestPage.module.scss";
import cn from "classnames";
import SideBarLayout from "./components/sideBarLayout";
import { ReactComponent as OpenedIcon } from "Assets/openedFiltersPanel.svg";
import { ReactComponent as ClosedIcon } from "Assets/closedFiltersPanel.svg";
import Toolbar from "./components/toolbar";
import SidebarFilters from "./components/sidebarFilters";
import TableData from "./components/tableData";
import IgvAlertPopup from "./components/igvAlertPopup";
import SendForConfirmationPopup from "./components/sendForConfirmationPopup";
import UncheckConfirmationPopup from "./components/uncheckConfirmationPopup";
import SetTestPhenotype from './components/setTestPhenotypePopup';
import {
  getIgvAlertShow,
  getOnConfirmation,
  getUncheckConfirmationData,
  getLoadingStatus,
  getMutationTypesValues,
  getTestId,
  getSelectedMutationType,
  getTestPhenotype
} from "Store/selectors";
import { fetchTestMetadata } from "Actions/testActions";
import Spinner from "GenericComponents/spinner";
import { withRouter } from "react-router-dom";
import { fetchTableData } from "Actions/tableActions";

class SingleTestPage extends Component {
  constructor(props) {
    super(props);
    props.fetchTestMetadata(props?.match?.params?.testId);

    this.state = {
      sidebarToggle: true,
      isMutationType: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.getMutationTypesValues.length > 0 && !state.isMutationType) {
      const { fetchTableData, match, getMutationTypesValues } = props;
      fetchTableData({
        testId: match?.params?.testId,
        mutation: getMutationTypesValues[0]
      });
      return { isMutationType: true };
    }
    return null;
  }

  handleClick = () => {
    this.setState({
      sidebarToggle: !this.state.sidebarToggle
    });
  };

  render() {
    const { sidebarToggle } = this.state;
    const {
      isIgvAlertShow,
      isOnConfirmation,
      uncheckConfirmationData,
      isLoading,
      testPhenotype,
      testId,
    } = this.props;

    return (
      <div className={style["main-page"]}>
        <div
          className={cn(["sidebar-wrapper", { "sidebar-open": sidebarToggle }])}
        >
          <SideBarLayout
            handleClick={this.handleClick}
            mode={sidebarToggle}
            iconOpened={<OpenedIcon />}
            iconClosed={<ClosedIcon />}
          >
            <SidebarFilters />
          </SideBarLayout>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div
            className={cn([
              "main-content-wrapper",
              { "sidebar-open": sidebarToggle }
            ])}
          >
            <div
              className="toolbar-collapse"
            >
              <Toolbar
                sidebarToggle={sidebarToggle}
                showFilter
                showSort
                showLoadBam
                showExportButton
                showAddResult
              />
            </div>
            <TableData />
          </div>
        )}
        {!!isIgvAlertShow && <IgvAlertPopup />}
        {!!isOnConfirmation && <SendForConfirmationPopup />}
        {!!uncheckConfirmationData && <UncheckConfirmationPopup />}
        {!testPhenotype && testId &&
          <SetTestPhenotype testId={testId} />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isIgvAlertShow: getIgvAlertShow(state),
    isOnConfirmation: getOnConfirmation(state),
    uncheckConfirmationData: getUncheckConfirmationData(state),
    isLoading: getLoadingStatus(state),
    getMutationTypesValues: getMutationTypesValues(state),
    mutation: getSelectedMutationType(state),
    testId: getTestId(state),
    testPhenotype: getTestPhenotype(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchTableData: data => dispatch(fetchTableData(data)),
    fetchTestMetadata: testId => dispatch(fetchTestMetadata(testId)),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SingleTestPage)
);
