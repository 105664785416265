import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import ErrorIcon from 'Assets/error-notification.svg';
import style from './VariantConflictsUsedNotifications.module.scss';

import {
  getVariantUsedConflicts
} from 'Store/selectors';

const VariantConflictsUsedNotifications = ({ data }) => {
  if (!data.length) return '';

  return (
    <div className={style['variant-conflicts-used-notifications']}>
      {data.map(item => <div key={item.id} className="variant-conflicts-used-item">
        <div
          className="conflict-icon"
          style={{
            backgroundImage: `url(${ErrorIcon})`
          }}
        />
        <div className="conflict-message">
          <span>{item?.user?.name}</span> - is investigating this variant too
        </div>
      </div>)}
    </div>
  );
};

VariantConflictsUsedNotifications.propTypes = {
  data: PropTypes.array
};
VariantConflictsUsedNotifications.defaultProps = {
  data: []
};

const mapStateToProps = state => {
  return {
    data: getVariantUsedConflicts(state),
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(VariantConflictsUsedNotifications));
