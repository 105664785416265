import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { login } from "Store/actions/authActions";
import { getLoginStatus } from "Store/selectors";
import WrappedLoginForm from "./components/loginForm/LoginForm";
import style from './Login.module.scss';

class LoginPage extends Component {

  constructor(){
    super();
    this.state = {};
  }


  static getDerivedStateFromProps(props) {

    if (props.isLoggedIn) {
      props.history.push('/');
    }
    return null;
  }



  render() {

    return (
      <div
        className={`${style["login-wrapper"]} flex justify-center`}
      >
        <WrappedLoginForm login={this.props.login}/>
      </div>

    );
  }
}


const mapStateToProps = state => {
  return {
    isLoggedIn: getLoginStatus(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    login: data => dispatch(login(data))
  };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));