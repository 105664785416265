import types from "../actionsTypes";

export const login = data =>{
  return{
    type: types.LOGIN,
    payload: data
  };
};

export const loginSuccess = data =>{
  return{
    type: types.LOGIN_SUCCESS,
    payload: data
  };
};

export const logout = data =>{
  return{
    type: types.LOGOUT,
    payload: data
  };
};



