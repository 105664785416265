import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cn from "classnames";

import Spinner from "GenericComponents/spinner/Spinner";
import LockImg from 'Assets/lock.svg';

import style from "./TestsPage.module.scss";

import { fetchTests } from "Store/actions/testsActions";
import { getLoadingStatus, getTests } from "Store/selectors";

import { dateOptions, capitalizeFirstLetter } from "Utils/helpers";

class TestsPage extends Component {

  componentDidMount() {
    this.props.fetchTests();
  }

  render() {
    const { tests, isLoading } = this.props;

    return (
      <div className={style["wrapper"]}>
        {isLoading ? (
          <Spinner />
        ) : (
          <div>

            {tests.map(test => <div key={test.id} className={style["row"]}>

              <div className={cn([
                style["row-section"],
                style["row-section-gsid"],
              ])}>
                <div className={style["row-section-title"]}>GSID</div>
                <div className={style["row-section-value"]}>{test.gsid}</div>
              </div>

              <div className={cn([
                style["row-section"],
                style["row-section-panel-type"],
              ])}>
                <div className={style["row-section-title"]}>Panel type</div>
                <div className={style["row-section-value"]}>{capitalizeFirstLetter(test.panel_type)}</div>
              </div>

              <div className={cn([
                style["row-section"],
                style["row-section-links"],
              ])}>
                <div className={style["link"]}>
                  <div
                    className={cn(style["link-icon"], {
                      [style["link-icon-locked"]]: !!test.exported_at
                    })}
                    style={{
                      backgroundImage: test.exported_at ? `url(${LockImg})` : 'none'
                    }}
                  />
                  <Link
                    to={`/tests/${test.id}`}
                    className={style["link-link"]}
                  >
                    Variants
                  </Link>
                </div>

                <div className={style["link-div-line"]}/>

                <div className={style["link"]}>
                  <div className={style["link-icon"]}/>
                  <Link
                    to={`/final-report/${test.id}`}
                    className={style["link-link"]}
                  >
                    Final report
                  </Link>
                </div>
              </div>

              <div className={cn([
                style["row-section"],
                style["row-section-created-at"],
              ])}>
                <div className={style["row-section-title"]}>Created at</div>
                <div className={style["row-section-value"]}>
                  {new Date(test.created_at)
                    .toLocaleDateString("en-GB", dateOptions)
                    .split(" ")
                    .join("/")
                  }
                </div>
              </div>
            </div>)}

          </div>
        )}
        ;
      </div>
    );
  }
}

TestsPage.propTypes = {};

const mapStateToProps = state => {
  return {
    isLoading: getLoadingStatus(state),
    tests: getTests(state)
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchTests: () => dispatch(fetchTests())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestsPage);
