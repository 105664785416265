import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import style from './VariantConflictsPendingNotifications.module.scss';
import VariantConflictsPendingClassificationItem from './variantConflictsPendingClassificationItem';

import {
  getVariantPendingClassificationConflicts
} from 'Store/selectors';

const VariantConflictsPendingNotifications = ({ data }) => {
  if (!data.length) return '';

  return (
    <div className={style['variant-conflicts-pending-notifications']}>
      {data.map(item => <div key={item.id} className="variant-conflicts-pending-item">
        <VariantConflictsPendingClassificationItem
          item={item}
        />
      </div>)}
    </div>
  );
};

VariantConflictsPendingNotifications.propTypes = {
  data: PropTypes.array
};
VariantConflictsPendingNotifications.defaultProps = {
  data: []
};

const mapStateToProps = state => {
  return {
    data: getVariantPendingClassificationConflicts(state),
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(VariantConflictsPendingNotifications));
