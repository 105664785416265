import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import * as Sentry from "@sentry/browser";
import { watchSaga } from "Store/saga";
import App from "./App";
import reducers from "Store/reducers";
import "Css/index.scss";
import "antd/dist/antd.css";
import ErrorBoundaries from "./ErrorBoundaries";
import localForage from "localforage";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: process.env.NODE_ENV !== "development"
    })
  ]
});

const persistConfig = {
  key: "root",
  storage: localForage,
  whitelist: ['auth']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose,
  sagaMiddleware = createSagaMiddleware(),
  middlewares = [sagaMiddleware];

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares))),
  persistor = persistStore(store);

sagaMiddleware.run(watchSaga);

ReactDOM.render(
  <ErrorBoundaries>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundaries>,
  document.getElementById("root")
);
